const site = [
  {
    id: '1',
    name: 'Home',
    link: '#',
  },
  {
    id: '2',
    name: 'Programs',
    link: '#',
  },
  {
    id: '3',
    name: 'Services',
    link: '#',
  },
  {
    id: '4',
    name: 'About',
    link: '#',
  },
  {
    id: '5',
    name: 'Contact',
    link: '#',
  },
];

export default site;
