const legal = [
  {
    id: '1',
    name: 'Privacy Policy',
    link: '#',
  },
  {
    id: '2',
    name: 'Terms & Conditions',
    link: '#',
  },
];

export default legal;
