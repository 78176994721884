// NotFound.jsx

const NotFound = () => {
  return (
    <div className="flex flex-col justify-center items-center h-screen">
      <h1 className="text-4xl font-bold mb-4">404 - Page Not Found</h1>
      <p className="text-lg text-gray-700">
        The page you are looking for does not exist.
      </p>
    </div>
  );
};

export default NotFound;
